.js [data-module='collapse'] {
    .collapse {
        display: block;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: 0.25s ease;
        opacity: 0;

        &.show {
            max-height: 999em;
            overflow: visible;
            opacity: 1;
            visibility: visible;
        }
    }

    .card {
        margin-bottom: map_get($spacers, 2);
    }

    .card-header {
        position: relative;

        &::after {
            font-family: 'Font Awesome 5 Free', sans-serif;
            content: '\f0fe';
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }

        [data-add] {
            display: none;
        }

        &.show [data-add] {
            display: inline-block;
        }

        &.show::after {
            content: '\f146';
        }

        @include hover {
            cursor: pointer;
        }
    }
}
