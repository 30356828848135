.form-control-label {
    &.required::after {
        content: ' *';
    }
}

textarea.form-control {
    min-height: 115px;
}

.has-error .form-control,
textarea.parsley-error,
select.parsley-error,
input.parsley-error {
    border: 1px solid $red;
}

.error-message {
    color: $red;
    display: block;
    padding-top: 0.25rem;
    width: 100%;
}

select.custom-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: $input-border-radius;
    text-indent: 0.01px;
    text-overflow: '';
}

.choices {
    //.choices__inner {
    //    background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
    //    background-size: $custom-select-bg-size;
    //}

    .error-message {
        position: absolute;
        top: 100%;
        left: 0;
    }
}

.js select[data-module='choices'] {
    position: absolute;
    opacity: 0;
    left: -999em;
}


// Dirty dirty
#complaint_form {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 40px;

    .col-6 {
        flex: 0 0 100%;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    label {
        font-weight: 500;
    }

    .form-group {

        &:nth-child(2),
        &:first-child {
            position: relative;

            @include media-breakpoint-up(md) {
                padding-bottom: 20px;
                border-bottom: 1px solid $input-border-color;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 15px;
                height: 1px;
                background-color: #e4e5e6;
            }

            label {
                width: 100%;
            }
        }

        &:nth-child(2) {
            &::before {
                left: auto;
                right: 0;
            }
        }
    }

    .btn-primary {
        margin-left: 15px;
    }

    .file-list {
        font-size: 0.8rem;
    }
}

#complaint_form_uploadedFiles {
    display: none;
}

label[for='complaint_form_uploadedFiles'] {
    background-color: $primary;
    color: $white;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
}
