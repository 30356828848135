.card-header {
    font-size: 1.2rem;
}

.card-footer {
    padding-left: 1rem;
    padding-right: 1rem;
}

.card {
    &.bg-success {
        background-color: $white !important;
        border-color: $black !important;
        color: $black !important;
    }
}
