.app-body {
    margin-top: 0;
}

.app-header {
    position: relative;
    height: auto;
    text-align: left;

    .navbar-brand {
        position: relative;
        margin-left: 0;
        left: 0;
        height: auto;
        width: auto;
    }

    .navbar-toggler {
        display: none;
    }

    .form-inline {
        .form-control {
            min-width: 300px;
        }

        .btn {
            position: absolute;
            right: 0.5rem;
            top: 0;
            font-size: 1.2rem;
            line-height: 1;
            color: $gray-400;
            background: transparent;
        }
    }

    .dropdown-toggle {
        i {
            vertical-align: middle;
        }
    }

    .navbar-nav {
        .active {
            font-weight: bold;
            position: relative;

            &::after {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: $gray-200;
                content: '';
                display: block;
                height: 1px;
            }
        }
    }
}
