.btn:not(.btn-link) {
    font-family: Roboto, sans-serif;
}

.btn-discrete {
    background: #45515b;
    color: $white;
    border: 1px solid $input-border-color;
}

.btn-primary {
    color: $white;
}

.btn {
    i {
        pointer-events: none;
    }
}
