.table-hover {
    tr, td {
        transition: 0.25s ease;
    }

    tr > td:first-child a {
        text-decoration: none;
        color: $body-color;
    }

    tbody tr:hover {
        td {
            cursor: pointer;
            background: lighten($blue, 55%);

            .darkmode & {
                background: lighten($black, 40%);
            }
        }
    }
}

.table th > a {
    color: $table-head-color;
}

.table th, .table td {
    vertical-align: middle;
}

.table-striped {
    thead th {
        border: 0;
        border-bottom: 2px solid $table-border-color;
    }
}

.table-collection {
    thead th {
        border: 0;

        label {
            margin-bottom: 0;
        }
    }
}
