.app-header + .alert-timetravel {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin: 0;
    width: 100%;
    position: fixed;
    top: 55px;
    z-index: $zindex-sticky - 1;
}

.alert-timetravel ~ .app-body {
    margin-top: 102px;
}

.alert-danger {
    width: 100%;
}
