.app-footer {
    background-color: $primary;
    color: $white;
    font-size: 0.9rem;

    .container {
        padding: 0;

        @include media-breakpoint-up(md) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
