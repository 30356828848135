// Variable overrides
// Note: most of the styling can be done simply via variable overrides

$font-size-base: 1rem;

$primary: #c3242c;
$secondary: #002768;
$info: #009186;
$black: #23282c;

$input-border-radius: 5px;
$btn-border-radius: 5px;
$btn-font-weight: 700;
$alert-border-radius: 5px;

$headings-font-family: 'Open Sans', sans-serif;
$headings-font-weight: 700;

$input-bg: #fff;
$input-color: #495057;
$custom-select-color: #212529;
$input-border-color: #ccc;

// $card-cap-bg: #f3f3f3;
$card-border-radius: 5px;
$table-cell-padding: 0.5rem 1rem;

$sidebar-nav-link-active-color: #92e2f9;
$sidebar-nav-link-active-bg: transparent;
$sidebar-nav-link-active-icon-color: $sidebar-nav-link-active-color;

// Animations
$base-animation-duration: 150ms;
$base-animation-timing: cubic-bezier(0.55, 0, 0.1, 1); // Google swift bezier
