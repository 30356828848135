// Prevent weird behavior when opening modals
.modal-open {
    padding-right: 15px;

    .app-header {
        right: 15px;
        padding-left: 15px;
    }
}

.modal-inner {
    max-height: 9999em;
    transition: 250ms $base-animation-timing;
}

.modal.is-loading {
    .modal-inner {
        padding: 2rem 0;
        max-height: 0;
        background: url('../images/misc/ajax-loader.gif') no-repeat center center;

        .modal-body,
        .modal-footer {
            visibility: hidden;
        }
    }
}

.modal-body {
    > div[id*='_'] {
        @include make-row;
    }
}
