// CSS Entrypoint
//
// Author: Naam Developer <naam@e-sites.net>
// Since: 11-09-2018

/*
	ITCSS Layers

	0. Project settings / variables
	1. Default mixins and helper functions
	2. Ground-zero styles
	3. Unclassed HTML elements
	4. Third-party styles
	5. Design patters / no cosmetics
	6. Designed pieces of UI
	7. Specific pages / view styles
	8. Overrides, helpers, utilities
*/

@import '00-settings/_settings';
@import '01-tools/_tools';
@import '02-generic/_generic';
@import '03-base/_base';
@import '04-vendor/_vendor';
@import '05-objects/_objects';
@import '06-components/_components';
@import '07-pages/_pages';
@import '08-trumps/_trumps';
