.jumbotron {
    position: relative;
    background-image: url('../images/misc/road-work.jpg?rev=001');
    background-size: cover;
    color: $white;

    h1 {
        position: relative;
        z-index: 10;
        font-weight: 700;
        font-size: 2rem;
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
        letter-spacing: 1.2px;

        @include media-breakpoint-up(md) {
            font-size: 2.5rem;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background-color: $primary;
    }
}
